
/* Toastr */
@import "node_modules/ngx-toastr/toastr.css";

/* Ngx-datatable */
@import "node_modules/@swimlane/ngx-datatable/index.css";
@import "node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* Ng-select */
@import "@ng-select/ng-select/themes/default.theme.css";

/* ---------------------
	*** Theme Scss ***
-----------------------*/
@import "bootstrap/scss/bootstrap";
@import "style";
@import "responsive";


