.font-weight-bold {
  font-weight: bold;
}

.required-input {
  color: red !important;
}

.error-list {
  list-style-type: circle;
  padding-bottom: 10px;
}
.error-list li {
  color: red;
}

#mergeTable .datatable-footer {
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#mergeTable .datatable-header {
  position: absolute;
  top: 50px;
}

#mergeTable .datatable-body {
  margin-top: 100px;
}

.datatable-body-cell {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.ngx-datatable {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.custom-card,
.custom-card .card-header,
.custom-card .card-body,
.custom-card .card-footer {
  padding: 10px !important;
}

.page-wrapper .page-body-wrapper .page-title {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}

.form-control.ng-select {
  border: none;
  padding: 0;
}

.modal-custom-large-width .modal-dialog {
  max-width: 90%;
  width: 90%;
}

.modal-custom-medium-width .modal-dialog {
  max-width: 66%;
  width: 66%;
}

.example-list {
  width: 1000px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 5px 5px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-img-close {
  position: absolute;
  top: -5px;
  right: 0px;
  font-size: 15px;
}

.drag-img-close:hover {
  font-size: 20px;
  color: red;
  cursor: pointer;
  top: -11px;
}

.link-tag {
  cursor: pointer;
  text-decoration: underline !important;
}
.link-tag-bg-blue {
  color: #80aaff;
}

.hide-dropdown-icon.dropdown-toggle::after {
  display: none;
}

.input-group-text {
  background-color: white;
  font-size: 13px;
}

.custom-selected-node {
  background-color: #ffb3b3;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-16 {
  font-size: 16px;
}

.custom-badge {
  color: white;
  display: inline-block;
  padding: 5px !important;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

hr.form-inside {
  border-top: 1px solid aqua;
}

.form-box-group {
  border: 1px dotted #dedede;
  padding: 5px;
  margin-bottom: 10px;
}

/*Dropdown option uzunluğuna göre alt satırada alıyor*/
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.active-element {
  color: green !important;
}

.passive-element {
  color: red !important;
}

.input-error {
  border: 1px solid red !important;
}

input[type="text"]:disabled {
  background: #faf8f8 !important;
  opacity: 0.9;
}

.input-show-hide-icon {
  margin-top: 10px;
  z-index: 1;
  margin-left: -30px;
  cursor: pointer;
}

.store-type-image {
  width: 20px !important;
  height: 20px !important;
}

.float-right {
  float: right;
}

.input-group-text {
  border-radius: 0 !important;
  font-size: 14px !important;
}

.ng-select .ng-select-container {
  border-radius: 0 !important;
}

.modal .close {
  border: none !important;
}

.dropdown-menu {
  margin: 0;
}

.group-prepend-update {
  height: 35px !important;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

#toast-container {
  position: fixed !important;
}

.loading-custom-class {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 3px;
}

.loading-custom-class h3 {
  color: black;
}

.loading-custom-class h6 {
  color: black;
}

.info-color {
  color: blue;
}

.product-image {
  width: 100px !important;
  height: 100px !important;
  padding: 5px !important;
}

/*tooltip-success-start*/
.tooltip-success .tooltip-inner {
  background-color: darkgreen;
  font-size: 125%;
}
.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: darkgreen;
}
.tooltip-success.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: darkgreen;
}
.tooltip-success.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: darkgreen;
}
.tooltip-success.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: darkgreen;
}
/*tooltip-success-end*/

/*tooltip-success-error*/
.tooltip-error .tooltip-inner {
  background-color: darkred;
  font-size: 125%;
}
.tooltip-error.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: darkred;
}
.tooltip-error.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: darkred;
}
.tooltip-error.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: darkred;
}
.tooltip-error.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: darkred;
}
/*tooltip-success-end*/

.ck-dd-box {
  padding: 5px 5px;
  border: 1px solid black;
  cursor: not-allowed;
  margin: 10px;
}

.ck-dd-box-match {
  padding: 5px 5px;
  border: 1px solid black;
  cursor: not-allowed;
  background-color: green;
  margin: 10px;
}

.general-box {
  padding: 5px;
  border: 1px solid grey;
  margin: 5px;
}

.select-list-group-item {
  background-color: green;
}

/*horizontal-item*/

.horizontal-item > li {
  display: inline-block;
  margin: 5px;
}

.custom-active-filter {
  padding: 10px;
  border: 1px solid green;
  position: relative;
}

.mat-form-field {
  display: block !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px;
}
.mat-form-field-infix {
  padding: 0;
  border-top: 0;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-select-arrow-wrapper {
  margin-top: 10px !important;
}

mat-radio-button {
  margin-right: 10px;
}

.uppercase-input {
  text-transform: uppercase;
}

.custom-file-input input[type="file"] {
  display: none;
}

/* Dosya seçme etiketinin stilini düzenleyin */
.custom-file-input label {
  background-color: #f7f7f7;
  color: black;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
}

/* Dosya seçme etiketine istediğiniz stil verin */
/* Örneğin, metni Türkçe yapmak için: */
.custom-file-input label {
  font-family: Arial, sans-serif;
}

/* Stili geliştirmek için istediğiniz diğer düzenlemeleri yapabilirsiniz */

.ml-3 {
  margin-left: 3px;
}

.search-page-2 {
  border: 1px solid gray;
  padding-left: 20px;
  border-radius: 10px 0 0 10px;
  flex: 1 1 auto;
}

.input-clear-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  padding-right: 80px;
}

.input-clear-icon:hover {
  color: #000; /* Temizleme simgesi üzerine gelindiğinde rengi değiştirin */
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-connected-position-bounding-box {
  z-index: 9999 !important;
}

.toast-container {
  z-index: 999999 !important;
}

.blink-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* bir elementin icindeki elementi hem yatay hemde dikey ortalar*/
.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-datatable
  .ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.active {
  background-color: white !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: white;
  color: black;
}

#resimKapsayici {
  position: relative;
}

#adetidini {
  position: absolute;
  top: 0;
  left: 0;
  color: white; /* Yazının rengini ayarla */
  font-size: 12px; /* Yazı boyutunu ayarla */
  background-color: cyan;
  padding: 1px;
}

.custom-material-dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.custom-material-dialog-footer > button {
  margin-left: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  background-color: white;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-button {
  margin-left: auto;
}

/* Navbar toggler için özel stil */
.navbar-toggler {
  border: 2px solid #fff; /* Beyaz sınır */
  background-color: transparent; /* Arka plan rengini şeffaf yapar */
}

/* Navbar toggler ikonunun rengi */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23fff'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6h16M4 12h16m-7 6h7' /%3E%3C/svg%3E"); /* Beyaz ikon */
}

/* Ekstra düzenleme: Toggler üzerine gelindiğinde sınır rengini değiştirmek için */
.navbar-toggler:hover {
  border-color: #ccc; /* Üzerine gelindiğinde gri sınır */
}

.mat-mdc-form-field {
  width: 100% !important;
}